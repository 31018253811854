<template>
  <section class="tag-management-wrap" v-loading="loading">
    <!-- <section class="filter">
      <el-form :inline="true" :model="form">
        <el-form-item label="关键词">
          <el-input v-model="form.keyWords" placeholder="搜索规则名称、通知标题"></el-input>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </section> -->
    <section class="content">
      <div class="btn-wrap" style="float:left;">
        <el-button size="small" type="primary" @click="dialogVisible = true">创建</el-button>
      </div>
      <div style="float: right">
        <el-input style="width: 200px;margin-right: 10px" v-model="keyword"></el-input>
        <el-button size="small" type="primary" @click="search">查询</el-button>
      </div>
      <el-table
          :data="tableData"
          row-key="id"
          lazy
          :load="loadChildrenTag"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          border max-height="calc(100% - 40px)"
          style="width: 100%">
          <el-table-column  :resizable="false"
            label="标签组名称"
            align="left">
            <template scope="{ row }">
              <span v-if="!row.isChild" style="">{{ row.groupName }}</span>
              <span v-if="row.isChild">{{ row.groupName }}</span>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="number"
            label="数量"
            align="center">
            <template scope="{ row }">
              <el-tooltip v-if="!row.isChild" class="item" effect="dark" content="标签数量" placement="top">
                <span v-if="!row.isChild" style="color: #3086FF;cursor: pointer;" @click="toTags(row.id)">{{row.number}}</span>
              </el-tooltip>
              <el-tooltip v-if="row.isChild" class="item" effect="dark" content="新闻数量" placement="top">
                <span v-if="row.isChild">{{row.number}}</span>
              </el-tooltip>
            </template>
          </el-table-column>
<!--          <el-table-column  :resizable="false"-->
<!--            prop="creator"-->
<!--            align="center"-->
<!--            label="创建者">-->
<!--          </el-table-column>-->
          <el-table-column  :resizable="false"
            align="center"
            label="创建时间">
            <template scope="{ row }">
              {{ row.createDate.substr(0, 10) }}
            </template>
          </el-table-column>
          <el-table-column  :resizable="false"
            align="center"
            label="操作">
            <template scope="{ row }">
              <el-button v-if="!row.isChild" type="text" style="color:#3472c7" @click="alterMarkGroupDialog(row)">修改</el-button>
              <el-button v-if="!row.isChild" type="text" style="color:#3472c7" @click="deleteMarkGroupDialog(row)">删除</el-button>
            </template>
          </el-table-column>
      </el-table>
      <section class="list-bottom">
          <span class="count">共{{count}}条记录</span>
          <el-pagination
            :current-page="currentPage"
            :page-sizes="[10 , 20, 30, 50]"
            :page-size="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
            @size-change="sizeChange"
            @current-change="currentChange">
          </el-pagination>
        </section>
    </section>
    <el-dialog
      title="创建"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form label-position="top" label-width="80px" :model="addForm" ref="addForm" :rules="rules">
        <el-form-item label="标签组" prop="groupName">
          <el-input v-model="addForm.groupName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelMarkGroup">取 消</el-button>
        <el-button type="primary" @click="saveMarkGroup">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改"
      :visible.sync="alterMarkGroupFlag"
      width="30%">
      <el-form label-position="top" label-width="80px" :model="alterForm" ref="alterForm" :rules="rules">
        <el-form-item label="标签组名:" prop="groupName">
          <el-input v-model="alterForm.groupName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAlterMarkGroup">取 消</el-button>
        <el-button type="primary" @click="updateMarkGroup">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="deleteMarkGroupFlag"
      width="30%"
      center>
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDeleteMarkGroup">取 消</el-button>
        <el-button type="primary" @click="confirmDeleteMarkGroup">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { initTag, addTag, showgroupName, editTag, deleteTag, listMarksById } from '@/api/settings/tag'
export default {
  data () {
    return {
      loading: true,
      form: {
        keyWords: ''
      },
      keyword: '',
      tableData: [],
      deleteMarkGroupId: '',
      dialogVisible: false,
      alterMarkGroupFlag: false,
      deleteMarkGroupFlag: false,
      addForm: {
        groupName: ''
      },
      alterForm: {
        id: '', // 待修改的标签组id
        groupName: '' // 新的标签组名称
      },
      rules: {
        groupName: [{ required: true, message: '请输入主题名称', trigger: 'blur' }]
      },
      limit: 10,
      currentPage: 1,
      count: 0
    }
  },
  methods: {
    async search () {
      this.currentPage = 1
      this.dataInit()
    },
    async loadChildrenTag (tree, treeNode, resolve) {
      const res = await listMarksById({ groupId: tree.id, current: 1, pageSize: tree.number, markName: this.form.keyWords, keyword: this.keyword })
      var children = res.data
      for (var i = 0; i < children.length; i++) {
        children[i].isChild = true
        children[i].number = children[i].num
        children[i].groupName = children[i].markName
      }
      resolve(children)
    },
    toTags (id) {
      this.$router.push('/settings/project/tag/' + id)
    },
    // 取消删除
    cancelDeleteMarkGroup () {
      this.deleteMarkGroupFlag = false
    },
    // 确定删除
    async confirmDeleteMarkGroup () {
      try {
        const res = await deleteTag({ id: this.deleteMarkGroupId })
        if (res.code === 0) {
          this.currentPage = 1
          this.form.keyWords = ''
          this.dataInit()
          this.$message.success('删除成功')
        } else {
          this.$message.error(res.msg)
        }
      } catch (err) {
        this.$message.error('删除失败')
        console.log(err)
      }
      this.deleteMarkGroupFlag = false
    },
    // 显示-删除标签组弹窗
    deleteMarkGroupDialog (row) {
      this.deleteMarkGroupId = row.id
      this.deleteMarkGroupFlag = true
    },
    // 取消修改标签组名称
    cancelAlterMarkGroup () {
      this.alterMarkGroupFlag = false
      this.$refs.alterForm.resetFields()
    },
    // 更新标签组名称
    async updateMarkGroup () {
      this.$refs['alterForm'].validate(async (valid) => {
        if (valid) {
          try {
            const res = await editTag({ id: this.alterForm.id, groupName: this.alterForm.groupName })
            if (res.code === 0) {
              this.form.keyWords = ''
              this.dataInit()
              this.alterMarkGroupFlag = false
              this.$refs.alterForm.resetFields()
              this.$message.success('修改成功')
            } else {
              this.$message.error(res.msg)
            }
          } catch (err) {
            this.$message.error('修改失败')
            console.log(err)
          }

        } else {
          console.log('error submit!!')
          return false
        }

      });

    },
    // 显示-修改标签组的弹窗
    async alterMarkGroupDialog (row) {
      this.alterMarkGroupFlag = true
      console.log(row)
      this.alterForm.id = row.id
      // const res = await showgroupName({ id: row.id })
      this.alterForm.groupName = row.groupName
    },
    // 初始化数据的公共方法
    async dataInit () {
      this.tableData = []
      try {
        const res = await initTag({ pageSize: this.limit, current: this.currentPage, groupName: this.form.keyWords, keyword: this.keyword })
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].number > 0) {
            res.data[i].hasChildren = true
          }
        }
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    },
    // 取消保存
    cancelMarkGroup () {
      this.dialogVisible = false
      this.$refs.addForm.resetFields()
    },
    // 保存新增的标签组
    saveMarkGroup () {
      this.$refs['addForm'].validate(async (valid) => {
        if (valid) {
          try {
            const res = await addTag({ groupName: this.addForm.groupName })
            if (res.code === 0) {
              this.currentPage = 1
              this.form.keyWords = ''
              this.dataInit()
              this.dialogVisible = false
              this.$refs.addForm.resetFields()
              this.$message.success('添加成功')
            } else {
              this.$message.error(res.msg)
            }
          } catch (err) {
            this.$message.success('添加失败')
            console.log(err)
          }

        } else {
          console.log('error submit!!')
          return false
        }
      });
    },
    handleClose () {
      this.dialogVisible = false
    },
    async sizeChange (i) {
      this.limit = i
      this.dataInit()
    },
    async currentChange (i) {
      this.currentPage = i
      this.dataInit()
    },
    async onSubmit () {
      this.currentPage = 1
      try {
        const res = await initTag({ pageSize: this.limit, current: this.currentPage, groupName: this.form.keyWords, keyword: this.keyword })
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].number > 0) {
            res.data[i].hasChildren = true
          }
        }
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    }
  },
  async mounted () {
    eventHub.$emit('msg', '标签组管理')
    try {
      const res = await initTag({ pageSize: this.limit, current: this.currentPage })
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].number > 0) {
          res.data[i].hasChildren = true
        }
      }
      this.tableData = res.data
      this.count = res.count
    } catch (err) {
      console.log(err)
    }
    this.loading = false
  }
}
</script>

<style lang="scss" scoped="">
.tag-management-wrap {
  width: 100%;
  height: 100%;
  .filter {
    width: 100%;
    height: 80px;
    padding: 20px;
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:2px;
    margin-bottom: 30px;
    .btn-item {
      margin-left: 25px;
    }
  }
  .content {
    width: 100%;
    min-height: calc(100% - 110px);
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:2px;
    padding: 20px;
    position: relative;
    .btn-wrap {
      margin-bottom: 25px;
    }
    .el-table {
      margin-bottom: 50px;
      .el-button--text {
        padding: 0;
      }
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
  }
  .el-dialog__wrapper {
    .el-dialog__body {
      padding: 0px 20px;
    }
  }
}
</style>
