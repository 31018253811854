import request from '@/utils/request'

// 初始化数据
export function initTag (data) {
  return request({
    url: '/mark/tsmarkgroup/listMarkGroup',
    method: 'POST',
    data
  })
}
// 添加标签组
export function addTag (data) {
  return request({
    url: '/mark/tsmarkgroup/addMarkGroup',
    method: 'POST',
    data
  })
}
// 回显标签组名称
export function showTagName (data) {
  return request({
    url: '/mark/tsmarkgroup/getMarkGroupById',
    method: 'POST',
    data
  })
}
// 修改标签组名称
export function editTag (data) {
  return request({
    url: '/mark/tsmarkgroup/editGroupNameById',
    method: 'POST',
    data
  })
}
// 删除标签组
export function deleteTag (data) {
  return request({
    url: '/mark/tsmarkgroup/removeMarkGroupById',
    method: 'POST',
    data
  })
}
// 查询某一个标签组下的标签
export function listMarksById (data) {
  return request({
    url: '/mark/tsmarkgroup/listMarksById',
    method: 'POST',
    data
  })
}

// 标签删除
export function delectMark (data) {
  return request({
    url: '/mark/tsmarkgroup/delectMark',
    method: 'POST',
    data
  })
}

// 标签修改
export function updateMark (data) {
  return request({
    url: '/mark/tsmarkgroup/updateMark',
    method: 'POST',
    data
  })
}
// 标签创建
export function saveMark (data) {
  return request({
    url: '/mark/tsmarkgroup/saveMark',
    method: 'POST',
    data
  })
}
// 通过ID获取标签
export function markById (data) {
  return request({
    url: '/mark/tsmarkgroup/markById',
    method: 'POST',
    data
  })
}

export function testApi (data) {
  return request({
    url: '/mark/tsmarkgroup/test',
    method: 'POST',
    data
  })
}
